import { Widgets } from "modules/Employee/Widgets";
import { Bilding } from "components/Bilding";
import { PostMVP } from "components/PostMVP";
import { SearchAdvanced } from "modules/SearchAdvanced";
import { PersonalInformation } from "modules/Employee/Profiles/Collaborator/PersonalInformation";
import { CerezaNotAccess } from "utils/CerezaNotAccess";
import { KeyIndicators } from "modules/Employee/Home/KeyIndicators";
import { WorkTeam } from "modules/Employee/Home/WorkTeam";
import { Petitions } from "modules/Employee/Requests/Petitions";
import { StaffRequest } from "modules/Recruitment/StaffRequests/StaffRequest";
import { Signature } from "modules/Requests/Signature";
import { RequestsHistory } from "modules/Requests/RequestsHistory";
import { Directory } from "modules/Employee/Home/Directory";
import { PresentationLetter } from "modules/Employee/Home";
import { NotificationsCenter } from "modules/Employee/NotificationsCenter";
import { AttendanceReports } from "modules/Employee/ShiftAttendance/AttendanceReports";
import { VacationsReport } from "modules/ShiftAttendance/VacationsReport";

export const EmployeeNavigationSource = [
  {
    name: "Inicio",
    path: "/Inicio",


    tree: [
      {
        name: "IndicadoresClave",
        path: "/home/indicators",
        is_nav: true,
        component: <KeyIndicators />,
        permissions: [],
      },
      {
        name: "Directorio",
        path: "/home/directorio",
        is_nav: true,
        component: <Directory />,
        permissions: [],
      },
      {
        name: "Documentos",
        path: "/home/documentos",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "WorkTeam",
        path: "/home/work-team",
        is_nav: true,
        component: <WorkTeam />,
        permissions: [],
      },
    ],
  },
  {
    name: "Requests",
    path: "/requests",
    is_nav: true,
    component: <Petitions application="employee" />,
    tree: [
    ],
  },
  {
    name: "Cuenta",
    path: "/Cuenta",


    tree: [
      {
        name: "Ajustes",
        path: "/account/configurations",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "GestiónDeUsuarios",
        path: "/account/management",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "InformaciónDeEmpleado",
        path: "/account/employee",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "Cuentas",
        path: "/account/accounts",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "Notificaciones",
        path: "/account/notifications",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "PlanesYFacturación",
        path: "/account/plans-billing",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "HorariosLaborales",
        path: "/account/working-hours",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
    ],
  },
  {
    name: "Perfil",
    path: "/Perfil",


    tree: [
      {
        name: "Perfil-Collaborator",
        path: "/profile/collaborator",


        tree: [
          {
            name: "Perfil-InformaciónPersonal",
            path: "/profile/collaborator/personal-information",
            is_nav: true,
            component: <PersonalInformation />,
            permissions: [
              "change_employees",
              "view_employees",
            ],
          },
          {
            name: "Perfil-DetallesDeContacto",
            path: "/profile/collaborator/contact-details",


            permissions: [
              "add_contactDetails",
              "change_contactDetails",
              "delete_contactDetails",
              "view_contactDetails",
            ],
          },
          {
            name: "Perfil-DocumentosDeIdentidad",
            path: "/profile/collaborator/identities-documents",


            permissions: [
              "add_identities",
              "change_identities",
              "delete_identities",
              "view_identities",
            ],
          },
          {
            name: "Perfil-HistorialAcadémico",
            path: "/profile/competencies/academy-history",


            permissions: [
              "add_academicBackground",
              "change_academicBackground",
              "delete_academicBackground",
              "view_academicBackground",
            ],
          },
          {
            name: "Perfil-Habilidades",
            fatherName: "Perfil-Habilidades",
            sonOf: "Perfiles",
            path: "/profile/competencies/skills-summary-tab",


            tree: [
              {
                name: "Perfil-Habilidades",
                sonOf: "Perfil-Habilidades",
                path: "/profile/competencies/skills-summary",


                permissions: [
                  "add_employeeSkills",
                  "change_employeeSkills",
                  "delete_employeeSkills",
                  "view_employeeSkills",
                ],
              },
              {
                name: "Perfil-Habilidades-Idiomas",
                sonOf: "Perfil-Habilidades",
                path: "/profile/competencies/skills-summary-languages",


                permissions: [
                  "add_employeeLanguages",
                  "change_employeeLanguages",
                  "delete_employeeLanguages",
                  "view_employeeLanguages",
                ],
              },
            ],
          },
          {
            name: "Perfil-EmpleoActual",
            path: "/profile/employment-information/current-employment",


            permissions: [],
          },
          {
            name: "Perfil-HistorialDeEmpleo",
            path: "/profile/employment-information/employment-summary",


            permissions: [
              "add_employmentHistory",
              "change_employmentHistory",
              "delete_employmentHistory",
              "view_employmentHistory",
            ],
          },
          {
            name: "Perfil-Compensación",
            path: "/profile/compensation",


            permissions: [],
          },
          {
            name: "Perfil-Documentos",
            path: "/profile/documents",


            permissions: ["add_files", "change_files", "view_files"],
          },
          {
            name: "Perfil-Assets",
            path: "/profile/assets",


            permissions: [],
          },
          {
            name: "Perfil-HistoralDeRegistro",
            path: "/profile/record-history",


            permissions: [],
          },
        ],
      },
      {
        name: "Estadísticas",
        path: "/profile/stats",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "Referencias",
        path: "/profile/references",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "DesarrolloProfesional",
        path: "/profile/desarrolloProfesional",
        is_nav: true,
        component: <PostMVP />,
        permissions: [],
      },
      {
        name: "RendimientoMétricas",
        path: "/profile/metricsPerformance",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/profile/profile-settings",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
    ],
  },
  {
    name: "TurnoDeAsistencia",
    path: "/TurnoDeAsistencia",


    tree: [
      {
        name: "AsistenciaAusencia",
        path: "/shift-attendance/attendance-absence",
        is_nav: true,
        component: <AttendanceReports />,
        permissions: [],
      },
      {
        name: "VacationsReport",
        path: "/shift-attendance/vacations-report",
        is_nav: true,
        component: <VacationsReport />,
        permissions: [
          "view_vacation_reports"
        ],
      },
      {
        name: "VacacionesDeTiempoLibre",
        path: "/shift-attendance/time-off-holidays",
        is_nav: true,
        component: <PostMVP />,
        permissions: [],
      },
      {
        name: "HorarioDeTurnoDeTrabajoDeCereza",
        path: "/shift-attendance/cereza-work-shift-schedule",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
    ],
  },
  {
    name: "CentroDeAprendizaje",
    path: "/CentroDeAprendizaje",


    tree: [
      {
        name: "AcademiaCereza",
        path: "/learning-center/cereza-academy",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "LearningHub",
        path: "/learning-center/learning-hub",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "EvaluaciónDeCompetencias",
        path: "/learning-center/competency-assessment",
        is_nav: true,
        component: <PostMVP />,
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/learning-center/marketplace-settings",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
    ],
  },
  {
    name: "CentroDeRendimiento",
    path: "CentroDeRendimiento",


    tree: [
      {
        name: "MisIndicadores",
        path: "/performance-center/key-indicators",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "Evaluaciones",
        path: "/performance-center/evaluaciones",
        is_nav: true,
        component: <PostMVP />,
        permissions: [],
      },
    ],
  },
  {
    name: "Recursos",
    path: "/Recursos",


    tree: [
      {
        name: "Cronograma",
        path: "/resources/schedule",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "Visitantes",
        path: "/resources/visitors",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
    ],
  },
  {
    name: "Médico",
    path: "/Médico",


    tree: [
      {
        name: "Incapacidades",
        path: "/medical/disabilities",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "Registro",
        path: "/medical/record",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "Historia",
        path: "/medical/history",
        is_nav: true,
        component: <PostMVP />,
        permissions: [],
      },
      {
        name: "CertificadoMédico",
        path: "/medical/medicalCertificate",
        is_nav: true,
        component: <PostMVP />,
        permissions: [],
      },
      {
        name: "Referrals",
        path: "/medical/medical-referrals",
        is_nav: true,
        component: <PostMVP />,
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/medical/medical-settings",
        is_nav: true,
        component: <PostMVP />,
        permissions: [],
      },
    ],
  },
  {
    name: "Herramientas",
    path: "/Herramientas",


    tree: [
      {
        name: "Junta",
        path: "/tools/board",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "InsigniaYLogro",
        path: "/tools/badge-achievement",
        is_nav: true,
        component: <Bilding />,
        permissions: [],
      },
      {
        name: "Boleto",
        path: "/tools/tickets",
        is_nav: true,
        component: <PostMVP />,
        permissions: [],
      },
    ],
  },
  {
    name: "RutasEspeciales",
    path: "/RutasEspeciales",
    tree: [
      {
        name: "SearchAdvanced",
        path: "/search/advanced",
        is_nav: true,
        component: <SearchAdvanced />,
        permissions: [],
      },
      {
        name: "WidgetPage",
        path: "/WidgetPage",
        is_nav: true,
        component: <Widgets />,
      },
      {
        name: "not",
        path: "/auth/not",
        is_nav: true,
        component: <CerezaNotAccess />,
      },
      {
        name: "StaffRequest",
        path: "/recruitment/staff-requests",
        is_nav: true,
        component: <StaffRequest application="employee" />,
      },
      {
        name: "Signature",
        path: "/requests/signature",
        is_nav: true,
        component: <Signature application="employee" />,
      },
      {
        name: "CreateRequests",
        path: "/requests/history",
        is_nav: true,
        component: <RequestsHistory application="employee" />,
      },
      {
        name: "NotificationsCenter",
        path: "/notifications/center",
        is_nav: true,
        component: <NotificationsCenter />,
      },
      {
        name: "PresentationLetter",
        path: "/presentation-letter",
        is_nav: true,
        component: <PresentationLetter />,
      },
    ],
  },
];
